<template>
  <section id="dashboard-analytics"></section>
</template>

<script>
export default {};
</script>

<style>
</style>
